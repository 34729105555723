// 公路专查模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 获取公路专查列表
export const queryHighroadList = (data) => {
  return request({
    url: '/api/highway/page',
    method: "post",
    data
  })
}

// 获取公路专查详情
export const getHighroadDetails = (params) => {
  return request({
    url: '/api/highway',
    method: "get",
    params
  })
}