<template>
  <div class="query_wrapper container">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="公路系统">
            <el-select
              style="width: 100%"
              v-model="form.sourceList"
              placeholder="请选择公路系统"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in sourceListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩地区">
            <el-select
              style="width: 100%"
              v-model="form.province"
              placeholder="请选择业绩地区"
              clearable
              filterable
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目类型">
            <el-select
              style="width: 100%"
              v-model="form.proTypeList"
              placeholder="请选择项目类型"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in proTypeListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="建设状态">
            <el-select
              style="width: 100%"
              v-model="form.stateList"
              placeholder="请选择建设状态"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in stateListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标企业">
            <el-input
              v-model.trim="form.companyName"
              placeholder="请输入中标企业关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="技术等级">
            <el-select
              style="width: 100%"
              v-model="form.technologyList"
              placeholder="请选择技术等级"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in technologyListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目经理">
            <el-input
              v-model.trim="form.projectManager"
              placeholder="请输入项目经理关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目总工">
            <el-input
              v-model.trim="form.chiefEngineer"
              placeholder="请输入项目总工关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="时间类型">
            <el-select
              style="width: 100%"
              v-model="form.startTimeType"
              placeholder="请选择开工时间类型"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in startTimeTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="开工时间">
            <el-date-picker
              class="flex1"
              v-model="startTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              :disabled="form.startTimeType.length === 0"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="时间类型">
            <el-select
              style="width: 100%"
              v-model="form.endTimeType"
              placeholder="请选择完工时间类型"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in endTimeTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="完工时间">
            <el-date-picker
              class="flex1"
              v-model="endTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              :disabled="form.endTimeType.length === 0"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="时间筛选逻辑">
            <el-radio-group v-model="form.dateFilterLogic">
              <el-radio :label="2">同时符合“开始时间”和“完成时间”</el-radio>
              <el-radio :label="1">符合任意时间均可</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目完整性">
            <el-select
              style="width: 100%"
              v-model="form.mustExistsFieldList"
              placeholder="请选择项目完整性"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in mustExistsFieldListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="金额类型">
            <el-select
              style="width: 100%"
              v-model="form.amountSoureList"
              placeholder="请选择金额类型"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in amountSoureListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩金额">
            <div class="flex alc">
              <el-input
                v-model.number.trim="form.minAmount"
                placeholder="最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number.trim="form.maxAmount"
                placeholder="最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="搜索范围">
            <el-select
              style="width: 100%"
              v-model="form.searchRangeList"
              placeholder="请选择关键词搜索范围"
              clearable
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in searchRangeListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="关键词" style="margin-bottom: 0px">
            <el-row
              class="mb22"
              v-for="(item, index) in keywordList"
              :key="'project' + index"
            >
              <el-col :span="21">
                <el-input
                  placeholder="请输入关键词"
                  v-model.trim="item.keyword"
                  class="input-with-select"
                  clearable
                >
                  <el-select
                    style="width: 100px"
                    v-model="item.radio"
                    slot="prepend"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option label="包含" value="包含"></el-option>
                    <el-option label="不包含" value="不包含"></el-option>
                  </el-select>
                </el-input>
              </el-col>

              <el-col :span="3" class="tac">
                <el-button
                  v-if="index == 0"
                  type="primary"
                  icon="el-icon-plus"
                  circle
                  plain
                  @click="addName"
                >
                </el-button>

                <el-button
                  v-else
                  @click="delName(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          条业绩信息
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name link" @click="toDetail(item.id, item.sourceCode)">
            {{ item.name }}
          </div>

          <el-row class="desc mb20">
            <el-col
              :span="8"
              class="desc_item company"
              :title="
                '中标企业:' + item.companyName && item.companyName != '-1'
                  ? item.companyName
                  : '--'
              "
            >
              <div class="left_text">中标企业：</div>
              <div class="right_content">
                {{ item.companyName | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="'中标金额：' + item.amount + '万元'"
            >
              <div class="left_text">中标金额：</div>
              <div class="right_content">{{ item.amount }}万元</div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '地区：' +
                (item.province && item.province != '-1' ? item.province : '--')
              "
            >
              <div class="left_text">地区：</div>
              <div class="right_content">
                {{ item.province | noVoid }}
              </div>
            </el-col>
          </el-row>
          <el-row class="desc">
            <el-col
              :span="8"
              class="desc_item"
              :title="
                '项目类型：' +
                (item.type && item.type != '-1' ? item.type : '--')
              "
            >
              <div class="left_text">项目类型：</div>
              <div class="right_content">
                {{ item.type | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '建设状态：' +
                (item.state && item.state != '-1' ? item.state : '--')
              "
            >
              <div class="left_text">建设状态：</div>
              <div class="right_content company">
                {{ item.state | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '更新时间：' +
                (item.updateTime && item.updateTime != '-1'
                  ? item.updateTime
                  : '--')
              "
            >
              <div class="left_text">更新时间：</div>
              <div class="right_content">
                {{ item.updateTime | noVoid }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :total="paginationTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 公路专查 */
// 引入ajax函数
import { queryHighroadList } from "@/api/highroad.js";
import { getDict } from "@/api/common";
import area from "@/utils/area"; // 引入区域json

export default {
  // 数据
  data() {
    return {
      firstId: "", // 第一条数据的ID
      form: {
        firstId: "",
        sourceList: [], // 公路系统
        proTypeList: [], // 项目类型
        stateList: [], // 建设状态
        companyName: "", // 中标企业
        searchRangeList: [], // 关键词搜索范围
        contProKwList: [], // 包含的项目关键词
        exclProKwList: [], // 不包含的项目关键词
        startTimeType: [], // 开始时间类型
        minStartTime: "", // 最小开工时间
        maxStartTime: "", // 最大开工时间
        endTimeType: [], // 完工时间类型
        minCompletionTime: "", // 最小交工时间
        maxCompletionTime: "", // 最大交工时间
        minProCompletionTime: "", // 最小竣工时间
        maxProCompletionTime: "", // 最大竣工时间
        dateFilterLogic: 2, // 日期筛选逻辑,同时满足几种条件
        amountSoureList: [], // 金额类型
        minAmount: "", // 最小金额
        maxAmount: "", // 最小金额
        technologyList: [], // 技术等级
        projectManager: "", // 项目经理
        chiefEngineer: "", // 项目总工
        mustExistsFieldList: [], // 项目完整性
        province: "", // 业绩地区

        pageNum: 1,
        pageSize: 20,
      },
      areaOptions: [], // 地区选项数据
      sourceListOptions: [], // 公路系统数据
      proTypeListOptions: [], // 项目类型数据
      stateListOptions: [], // 建设状态数据
      searchRangeListOptions: [], // 业绩关键词搜索范围数据
      startTimeTypeOptions: [], // 业绩时间-开工时间类型数据
      endTimeTypeOptions: [], // 业绩时间-完工时间类型数据
      amountSoureListOptions: [], // 业绩金额-金额类型数据
      technologyListOptions: [], // 技术等级数据
      mustExistsFieldListOptions: [], // 业绩完整性数据
      startTime: [], // 开工日期
      endTime: [], // 完工日期
      // 关键词
      keywordList: [
        {
          radio: "",
          keyword: "",
        },
      ],

      list: [], // 列表
      total: 0, // 总数
      paginationTotal: 0, // 翻页总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData(flag = false) {
      this.loading = true;

      let minStartTime = ""; // 最小开工时间
      let maxStartTime = ""; // 最大开工时间
      let minCompletionTime = ""; // 最小交工时间
      let maxCompletionTime = ""; // 最大交工时间
      let minProCompletionTime = ""; // 最小竣工时间
      let maxProCompletionTime = ""; // 最大竣工时间

      if (this.form.startTimeType.length > 0 && this.startTime.length > 0) {
        minStartTime = this.startTime[0];
        maxStartTime = this.startTime[1];
      }
      if (this.form.endTimeType.length > 0 && this.endTime.length > 0) {
        if (this.endTimeType.includes("交工时间")) {
          minCompletionTime = this.endTime[0];
          maxEndTime = this.endTime[1];
        }
        if (this.endTimeType.includes("竣工时间")) {
          minProCompletionTime = this.endTime[0];
          maxProCompletionTime = this.endTime[1];
        }
      }
      const contKeywordList = this.keywordList
        .filter((v) => v.radio == "包含" && v.keyword)
        .map((v) => v.keyword.trim());
      const exclKeywordList = this.keywordList
        .filter((v) => v.radio == "不包含" && v.keyword)
        .map((v) => v.keyword.trim());

      const params = {
        ...this.form,
        minStartTime, // 最小开工时间
        maxStartTime, // 最大开工时间
        minCompletionTime, // 最小交工时间
        maxCompletionTime, // 最大交工时间
        minProCompletionTime, // 最小竣工时间
        maxProCompletionTime, // 最大竣工时间
        contKeywordList,
        exclKeywordList,
        firstId: this.firstId,
      };

      const { code, data } = await queryHighroadList(params);
      if (code !== 0) return;
      data.records.forEach((v) => {
        v.amount = v.amount ? v.amount.toFixed(2) : 0;
      });
      this.list = data.records;
      if (flag) {
        this.firstId = this.list[0].id;
      }
      this.total = data.total;

      this.paginationTotal = this.total > 2000 ? 2000 : this.total;

      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 公路系统
      const sourceListOptions = await getDict({
        source: "roadSystem",
      });
      this.sourceListOptions = sourceListOptions.data;

      // 基本信息-项目类型
      const proTypeListOptions = await getDict({
        source: "roadProjectType",
      });
      this.proTypeListOptions = proTypeListOptions.data;

      // 基本信息-建设状态
      const stateListOptions = await getDict({
        source: "roadConstructionState",
      });
      this.stateListOptions = stateListOptions.data;

      // 业绩关键词搜索范围
      const searchRangeListOptions = await getDict({
        source: "roadKeyword",
      });
      this.searchRangeListOptions = searchRangeListOptions.data;

      // 业绩时间-开始时间类型
      const startTimeTypeOptions = await getDict({
        source: "roadStartTimeType",
      });
      this.startTimeTypeOptions = startTimeTypeOptions.data;

      // 业绩时间-完成时间类型
      const endTimeTypeOptions = await getDict({
        source: "roadEndTimeType",
      });
      this.endTimeTypeOptions = endTimeTypeOptions.data;

      // 业绩金额-金额类型
      const amountSoureListOptions = await getDict({
        source: "roadAmountType",
      });
      this.amountSoureListOptions = amountSoureListOptions.data;

      // 技术等级
      const technologyListOptions = await getDict({
        source: "roadTechnicalGrade",
      });
      this.technologyListOptions = technologyListOptions.data;

      // 业绩完整性
      const mustExistsFieldListOptions = await getDict({
        source: "roadPerformanceIntegrity",
      });
      this.mustExistsFieldListOptions = mustExistsFieldListOptions.data;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        name: "highroadDetails",
        params: { id },
      });
    },
    // 添加名称
    addName() {
      if (this.keywordList.length < 5) {
        this.keywordList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除名称
    delName(index) {
      this.keywordList.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        firstId: "",
        sourceList: [], // 公路系统
        proTypeList: [], // 项目类型
        stateList: [], // 建设状态
        companyName: "", // 中标企业
        searchRangeList: [], // 关键词搜索范围
        contProKwList: [], // 包含的项目关键词
        exclProKwList: [], // 不包含的项目关键词
        startTimeType: [], // 开始时间类型
        minStartTime: "", // 最小开工时间
        maxStartTime: "", // 最大开工时间
        endTimeType: [], // 完工时间类型
        minCompletionTime: "", // 最小交工时间
        maxCompletionTime: "", // 最大交工时间
        minProCompletionTime: "", // 最小竣工时间
        maxProCompletionTime: "", // 最大竣工时间
        dateFilterLogic: 2, // 日期筛选逻辑,同时满足几种条件
        amountSoureList: [], // 金额类型
        minAmount: "", // 最小金额
        maxAmount: "", // 最小金额
        technologyList: [], // 技术等级
        projectManager: "", // 项目经理
        chiefEngineer: "", // 项目总工
        mustExistsFieldList: [], // 项目完整性
        province: "", // 业绩地区

        pageNum: 1,
        pageSize: 20,
      };
      this.startTime = []; // 开工日期
      this.endTime = []; // 完工日期
      this.keywordList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 查询
    query() {
      this.form.pageNum = 1;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      // let name = this.$route.query.name;
      // let path = this.$route.params.name;
      // if (name && path == "查业绩") {
      //   this.keywordList[0] = {
      //     radio: "包含",
      //     keyword: name,
      //   };
      // }
      this.getOptions();
      this.getData(true);
    },
  },

  // 生命周期-实例激活状态
  async activated() {
    this.__init__();
  },

  // 生命周期-创建完成
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }

  .list {
    background-color: #fff;

    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px dashed #efefef;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .desc {
        // display: flex;
        // align-items: center;
        color: #999;

        .desc_item {
          // flex: 2;
          display: flex;
          align-items: center;
          // margin-right: 30px;
          .right_content {
            flex: 1;
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 溢出用省略号显示
            display: -webkit-box; // 作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
            -webkit-line-clamp: 1; // 显示的行数
          }
        }
      }

      .company {
        cursor: pointer;
        &:hover {
          color: #1d89e3;
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2) {
        border-top: none;
        border-top: 1px solid #efefef;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }

  /deep/.el-pagination {
    text-align: right;
    padding: 20px 30px;
  }

  /deep/.el-tabs__header {
    background-color: #fff;
    padding: 20px;
  }

  /deep/.el-form {
    background-color: #fff;
    margin-bottom: 30px;
    padding: 30px;
    padding-left: 20px;
  }

  /deep/.el-cascader,
  /deep/.el-date-editor {
    width: 100%;
  }

  /deep/.list {
    font-size: 16px;
    background-color: #fff;
    .desc {
      font-size: 14px;
    }
  }

  /deep/.total {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /deep/.el-date-editor .el-range-separator {
    width: 6%;
  }
}
</style>
